import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import {Helmet} from 'react-helmet'

import Layout from "../components/layout";
import SEO from "../components/seo";

import HeroFull from '../components/index/hero-full/hero-full';
import Intro from '../components/index/intro/intro';
import ServiceList from '../components/services/services-list';
import Testimonials from '../components/elements/testimonials/testimonials';
import BookCta from '../components/elements/book-cta/book-cta';
import InstaFeed from '../components/elements/insta-feed/insta-feed';

const flodesk = `
(function(w, d, t, h, s, n) {
w.FlodeskObject = n;
var fn = function() {
(w[n].q = w[n].q || []).push(arguments);
};
w[n] = w[n] || fn;
var f = d.getElementsByTagName(t)[0];
var v = '?v=' + Math.floor(new Date().getTime() / (120 * 1000)) * 60;
var sm = d.createElement(t);
sm.async = true;
sm.type = 'module';
sm.src = h + s + '.mjs' + v;
f.parentNode.insertBefore(sm, f);
var sn = d.createElement(t);
sn.async = true;
sn.noModule = true;
sn.src = h + s + '.js' + v;
f.parentNode.insertBefore(sn, f);
})(window, document, 'script', 'https://assets.flodesk.com', '/universal', 'fd');`

const flodeskForm = `
window.fd('form', {

  formId: '63e2d5e68e06d4dae94936dc'

});
`

const IndexPage = () => {
  
  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])
  
  return (
    <StaticQuery
    query={indexQuery}
    render={data => {
      const serviceList = [
        {
          title: 'Hands & Feet',
          text: 'Discover',
          image: data.hands.childImageSharp.fluid,
          link: '/beauty/hands-and-feet'
        },
        {
          title: 'Massage',
          text: 'Discover',
          image: data.massage.childImageSharp.fluid,
          link: '/beauty/massage'
        },
        {
          title: 'Waxing',
          text: 'Discover',
          image: data.waxing.childImageSharp.fluid,
          link: '/beauty/waxing'
        },
        {
          title: 'Eye Treatments & Extensions',
          text: 'Discover',
          image: data.eye.childImageSharp.fluid,
          link: '/beauty/eye-treatments'
        },
        {
          title: 'Laser Hair Removal',
          text: 'Discover',
          image: data.laser.childImageSharp.fluid,
          link: '/beauty/laser-hair-removal'
        },
        {
          title: 'Hair Salon',
          text: 'Discover',
          image: data.hair.childImageSharp.fluid,
          link: '/hair'
        }
        ,
        {
          title: 'Pure Facials',
          text: 'Discover',
          image: data.facials.childImageSharp.fluid,
          link: '/beauty/pure-facials'
        }
        ,
        {
          title: 'Other Treatments',
          text: 'Discover',
          image: data.other.childImageSharp.fluid,
          link: '/beauty/other-treatments'
        }
      ]
      return (
        <Layout>
          <Helmet>
            <script>
              {`${flodesk}`}
            </script>
            <script>
              {`${flodeskForm}`}
            </script>
          </Helmet>
          <SEO
            keywords={[`Beauty Ely`, `Hair Salon Ely`, `Beauty Salon Ely`, `Nails Ely`, 'Nail Bar Ely', 'Massage Ely']}
            title="A Beauty Salon and Hair Salon based in Ely"
            description="Pamper yourself at either our Beauty or Hair Salon right in the heart of Ely, Cambridgeshire. At House Of Pure, we redefine your salon experience, with beautiful surroundings, leading in-salon technology, and impeccable treatments."
          />
          <HeroFull
            herofull={data.herofull.childImageSharp.fluid} 
            hero1={data.hero1.childImageSharp.fluid}
            hero2={data.hero2.childImageSharp.fluid}
            hero3={data.hero4.childImageSharp.fluid}
            hero4={data.hero3.childImageSharp.fluid}
            loaded={loaded} 
          />
          <Intro image={data.about.childImageSharp.fluid} />
          <ServiceList 
            height="45" 
            services={serviceList}
            title={"PAMPER YOURSELF AT OUR ELY SALON </br> BEAUTY - HAIR - AESTHETICS "}
          />
          <Testimonials />
          <BookCta discount="https://gift-cards.phorest.com/salons/houseofpureltd" link="https://phorest.com/book/salons/houseofpureltd" phone="01353 661 321" email="info@houseofpure.co.uk" bg={data.bookbg.childImageSharp.fluid} marginBottom />
          <InstaFeed token="IGQVJWSFBsWDctOXpzeVo4S1VNYjJOc0hHbnY4OHR4ZAUZAPc193c3pJRGY1M2NNVS16RnpRaHpJeEF3Yy1HRjN4OVQ2QndlZAzdhWFlsYkhoYTVOcmZA4cTYyMzFNOVBtdnBmX0VEbE5yVHFabkNka1VDdAZDZD" />
        </Layout>
      )}
    }
    />
  );
}

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero1: file(relativePath: { eq: "pure-ely-beauty-hoverover.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero2: file(relativePath: { eq: "pure-ely-Aesthetics-hoverover.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero3: file(relativePath: { eq: "pure-ely-academy-hoverover.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero4: file(relativePath: { eq: "pure-ely-hair-hoverover.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    about: file(relativePath: { eq: "pure-ely-beauty-salon-slippers.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hands: file(relativePath: { eq: "2022/hands-feet.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    massage: file(relativePath: { eq: "2022/massage.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    waxing: file(relativePath: { eq: "waxing-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    eye: file(relativePath: { eq: "waxing.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    laser: file(relativePath: { eq: "2022/laser.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hair: file(relativePath: { eq: "hair-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facials: file(relativePath: { eq: "2022/facials1.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    other: file(relativePath: { eq: "other-treatments-cta.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage;

