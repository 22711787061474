import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import introDots from '../../../images/intro-dots.svg';

import './intro.style.scss';

const Intro = ({image}) => {
  return (
    <div className="relative px-4">
      <div className="flex flex-wrap justify-center mx-auto max-w-screen-md mb-10 md:mb-32 text-center">
        <h1 className="uppercase text-4xl text-primary-dark leading-tight">WE ARE HOUSE OF PURE <br/> Beauty, Aesthetics, Hair and Training Academy based in ELY</h1>
      </div>
      <img style={{top: '-8.5%'}} className="absolute hidden lg:block left-0" src={introDots} />
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-5xl mb-16 md:mb-32">
        <div className="blue-strip hidden md:block absolute w-full h-full bg-primary" />
        <div className=" w-full md:w-1/3 mb-5 md:mb-0">
          {image &&<Img className="w-full" fluid={image} />}
        </div>
        <div className="md:pl-16 w-full md:w-2/3 text-center max-w-2xl mx-auto">
          <h3 className="uppercase text-4xl text-primary-dark leading-tight mb-4">The House Of Pure Story</h3>
          <p className="font-light mb-5">HOUSE OF PURE strives to bring you the latest in Beauty, Hair and Aesthetics treatments. We are constantly evolving our business to bring you the latest treatments all under one roof and one trusted brand.  We are fast approaching our first year in our new premises and once again Covid did not get us!  2022 has started with us announcing the launch of our new Retail Store in High Street Passage Ely.  We are aiming to bring you a little haven where you can try our products, get a curl & go, have an express facial or your teeth whitened.</p>
          <a target={'_blank'} className="bordered-btn bg-primary uppercase text-white text-base px-8 py-5 inline-block" href="https://link-to.app/SEgPOIMIcu" rel="noreferrer" >
            <span className="z-10 relative bordered-btn__text">Download our app</span>
          </a>
        </div>
      </div>
    </div>
  );
}


Intro.propTypes = {
  image: PropTypes.object,
};


export default Intro;
